import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import AppMenu from '@/components/AppMenu';
import { PaymentMethod, SuccessView } from 'remote/global';
import RootSetup from 'setup/index';
import { ROUTES } from 'utils/routes';
import LoginView from 'views/LoginView';
import ChangePasswordView from 'views/ChangePasswordView';
import ResetPasswordTokenView from 'views/ResetPasswordTokenView';
import RedirectView from 'views/RedirectView';
import OrderEditView from 'views/OrderEditView';
import OrderCompleteView from 'views/OrderCompleteView';
import * as Sentry from '@sentry/react';
import BecomeOperatorView from 'views/BecomeOperatorView';
import FAQView from 'views/FAQView';
import ContactView from 'views/ContactView';
import RatingsView from '@/views/RatingsView';
import LegalTextView from 'views/LegalTextView';
import OffersView from 'views/OffersView';
import ContinueBookingView from 'views/ContinueBookingView';
import DeepLinkRedirectView from 'views/DeepLinkRedirectView';
import LogoutView from 'views/LogoutView';
import MakeOfferView from 'views/MakeOfferView';
import InternalTestingView from 'views/InternalTestingView';
import OrderDriverView from 'views/OrderDriverView';
import IndexView from 'views/IndexView';
import EditBookingRedirectView from 'views/EditBookingRedirectView';
import { isLocal, isProd } from '../helpers';

// TODO: Add <Navigate /> fallback for parent routes that find no match within child routes

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const isInternalTestingViewEnabled = isLocal || isProd;
const paymentMethod =
  new URLSearchParams(window.location.search).get('type') || PaymentMethod.PREPAID;

const useAppRouter = () => {
  const { t } = useTranslation();

  const PUBLIC_ROUTES = [
    <Route path={ROUTES.BaseRoutes.ROOT} element={<IndexView />} key={ROUTES.BaseRoutes.ROOT} />,
    <Route path={ROUTES.BaseRoutes.LOGIN} element={<LoginView />} key={ROUTES.BaseRoutes.LOGIN} />,
    <Route path={ROUTES.BaseRoutes.PASSWORD_RESET} key={ROUTES.BaseRoutes.PASSWORD_RESET}>
      <Route index element={<ResetPasswordTokenView />} />
      <Route path=':id' element={<ChangePasswordView />} />
    </Route>,
    <Route
      path={ROUTES.BaseRoutes.MAKE_OFFER}
      key={ROUTES.BaseRoutes.MAKE_OFFER}
      element={<MakeOfferView />}
    />,
    <Route
      path={ROUTES.BaseRoutes.ORDER_DRIVER}
      key={ROUTES.BaseRoutes.ORDER_DRIVER}
      element={<OrderDriverView />}
    />,
    <Route
      path={ROUTES.BaseRoutes.BECOME_OPERATOR}
      element={<BecomeOperatorView />}
      key={ROUTES.BaseRoutes.BECOME_OPERATOR}
    />,
    <Route path={ROUTES.BaseRoutes.FAQ} element={<FAQView />} key={ROUTES.BaseRoutes.FAQ} />,
    <Route
      path={ROUTES.BaseRoutes.CONTACT}
      element={<ContactView />}
      key={ROUTES.BaseRoutes.CONTACT}
    />,
    <Route
      path={ROUTES.BaseRoutes.TERMS_OF_SERVICE}
      element={<LegalTextView />}
      key={ROUTES.BaseRoutes.TERMS_OF_SERVICE}
    />,
    <Route
      path={ROUTES.BaseRoutes.PRIVACY_POLICY}
      element={<LegalTextView />}
      key={ROUTES.BaseRoutes.PRIVACY_POLICY}
    />,
    <Route
      path={ROUTES.BaseRoutes.COOKIES}
      element={<LegalTextView />}
      key={ROUTES.BaseRoutes.COOKIES}
    />,
    <Route
      path={ROUTES.BaseRoutes.REDIRECT}
      element={<RedirectView />}
      key={ROUTES.BaseRoutes.REDIRECT}
    />,
    <Route
      path={ROUTES.BaseRoutes.DRIVER_APP_REDIRECT}
      element={<DeepLinkRedirectView />}
      key={ROUTES.BaseRoutes.DRIVER_APP_REDIRECT}
    />,
    <Route
      path={ROUTES.BaseRoutes.OFFERS}
      element={<OffersView />}
      key={ROUTES.BaseRoutes.OFFERS}
    />,
    <Route
      path={ROUTES.BaseRoutes.CONTINUE_BOOKING}
      element={<ContinueBookingView />}
      key={ROUTES.BaseRoutes.CONTINUE_BOOKING}
    />,
    <Route
      path={ROUTES.BaseRoutes.RATINGS}
      element={
        <>
          <AppMenu />
          <Box
            sx={{
              padding: '0 1rem',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <RatingsView />
          </Box>
        </>
      }
      key={ROUTES.BaseRoutes.RATINGS}
    />,
    <Route
      path={ROUTES.BaseRoutes.ORDER_COMPLETE}
      element={<OrderCompleteView />}
      key={ROUTES.BaseRoutes.ORDER_COMPLETE}
    />,
    <Route
      path={ROUTES.BaseRoutes.CHANGE_BOOKING_DETAILS}
      element={<OrderEditView />}
      key={ROUTES.BaseRoutes.CHANGE_BOOKING_DETAILS}
    />,
    <Route
      path={ROUTES.BaseRoutes.CHANGE_BOOKING_REDIRECT}
      element={<EditBookingRedirectView />}
      key={ROUTES.BaseRoutes.CHANGE_BOOKING_REDIRECT}
    />,
    <Route
      path={ROUTES.BaseRoutes.BOOKING_CHANGED}
      element={
        <>
          <AppMenu />
          <Box
            sx={{
              padding: '0 1rem',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <SuccessView
              title={t(`OrderView.changed.title`).toString()}
              subtitle={t(`OrderView.changed.${paymentMethod}.subtitle`).toString()}
            />
          </Box>
        </>
      }
      key={ROUTES.BaseRoutes.BOOKING_CHANGED}
    />,
    <Route
      path={ROUTES.BaseRoutes.RATING_SUCCESS}
      element={
        <>
          <AppMenu />
          <Box
            sx={{
              padding: '0 1rem',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <SuccessView
              title={t(`RatingsView.success.title`).toString()}
              subtitle={t(`RatingsView.success.subtitle`).toString()}
              hideBackButton
            />
          </Box>
        </>
      }
      key={ROUTES.BaseRoutes.RATING_SUCCESS}
    />,
    <Route
      path={ROUTES.BaseRoutes.LOGOUT}
      element={<LogoutView />}
      key={ROUTES.BaseRoutes.LOGOUT}
    />,
    isInternalTestingViewEnabled && (
      <Route
        path={ROUTES.BaseRoutes.INTERNAL_TESTING}
        element={<InternalTestingView />}
        key={ROUTES.BaseRoutes.INTERNAL_TESTING}
      />
    ),
    <Route path='*' element={<Navigate to={ROUTES.BaseRoutes.ROOT} replace />} key='*' />,
  ];

  const routes = PUBLIC_ROUTES;

  const router = sentryCreateBrowserRouter(
    createRoutesFromElements([
      <Route key='RootSetup' element={<RootSetup />}>
        {routes}
      </Route>,
    ])
  );

  return router;
};

export default useAppRouter;
