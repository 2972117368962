enum BaseRoutes {
  ROOT = '/',
  LOGIN = '/log-in',
  SIGNUP = '/sign-up', // There is no actual sign up page
  PASSWORD_RESET = '/password-reset',
  INTERNAL_TESTING = '/internal-testing',
  OFFERS = '/offers/:refNumber',
  RATINGS = '/ratings/:token',
  CONTINUE_BOOKING = '/offers/continue-booking/:refNumber/:uuid',
  CHANGE_BOOKING_DETAILS = '/change-booking-details/:bookingNumber',
  CHANGE_BOOKING_REDIRECT = '/change-booking-redirect/:bookingNumber/:uuid',
  BOOKING_CHANGED = '/change-booking-details/:bookingNumber/changed',
  ORDER_COMPLETE = '/order-complete/:bookingNumber/:uuid',
  BECOME_OPERATOR = '/become-operator',
  RATING_SUCCESS = '/rating-success',
  FAQ = '/faq',
  CONTACT = '/contact',
  TERMS_OF_SERVICE = '/terms-of-service',
  PRIVACY_POLICY = '/privacy-policy',
  COOKIES = '/cookies',
  REDIRECT = '/redirect',
  DRIVER_APP_REDIRECT = '/driver-app-redirect/:subpath/:id',
  LANGUAGE = '/language',
  LOGOUT = '/logout',
  MAKE_OFFER = '/o/:token',
  ORDER_DRIVER = '/order-driver/:token',
  EXCEPTION = '*',
}

enum LanguageRoutes {
  LANGUAGE_ET = 'language_et',
  LANGUAGE_EN = 'language_en',
}

export const ROUTES = {
  BaseRoutes,
  LanguageRoutes,
};

const BASE_KEYS = Object.values(ROUTES.BaseRoutes);
const LANGUAGE_KEYS = Object.values(ROUTES.LanguageRoutes);

export type RouteItemKey = (typeof BASE_KEYS)[number] | (typeof LANGUAGE_KEYS)[number];
