import { Grid, Paper, Typography } from '@mui/material';
import MapDisplay from 'components/MapDisplay';
import { useTranslation } from 'react-i18next';
import { colors } from 'remote/global';
import { Box } from '@mui/system';
import ContactForm from 'components/forms/ContactForm/ContactForm';
import AppMenu from 'components/AppMenu';
import Footer from 'components/Footer';
import margusMaiste from 'assets/margus-maiste.png';

interface PersonContactInfoProps {
  imgSrc: string;
  name: string;
  email: string;
  href: string;
  title: string;
  phone?: string;
}

function PersonContactInfo({ imgSrc, name, email, href, title, phone }: PersonContactInfoProps) {
  return (
    <Grid item xs={12} display='flex' maxWidth='xl' m='0 auto' gap={2}>
      <Grid item>
        <Box
          component='img'
          src={imgSrc}
          alt={name}
          sx={{
            display: 'flex',
            borderRadius: '50%',
            width: ['75px', undefined, undefined, '100px'],
            height: ['75px', undefined, undefined, '100px'],
            objectFit: 'cover',
            objectPosition: '100% 20%',
            border: `2px solid ${colors.primary}`,
          }}
        />
      </Grid>
      <Grid item display='flex' flexDirection='column' justifyContent='center'>
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
            color: colors.primary,
            fontWeight: 700,
            mb: '6px',
          }}
        >
          {name}
        </Typography>
        <Typography sx={{ fontSize: '16px', lineHeight: '24px', color: colors.gray1 }}>
          {title}
        </Typography>
        <Typography
          component='a'
          href={href}
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            textDecoration: 'underline',
            color: colors.gray1,
            display: 'flex',
          }}
        >
          {email}
        </Typography>
        <Typography
          component='a'
          href={`tel:${phone}`}
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            textDecoration: 'underline',
            color: colors.gray1,
            display: 'flex',
          }}
        >
          {phone}
        </Typography>
      </Grid>
    </Grid>
  );
}

function ContactInfo() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        color: colors.tiffanyBlue,
        background: '#FFF',
        border: `1px solid #80CED766`,
        px: '1rem',
        py: ['3rem', '4rem', '5rem', '10rem'],
      }}
    >
      <Grid container maxWidth='xl' m='0 auto' gap={2}>
        <Grid item xs={12} sm={6}>
          <Typography
            component='h3'
            sx={{
              fontSize: ['26px', undefined, '32px'],
              lineHeight: ['32px', undefined, '38px'],
              color: colors.tiffanyBlue,
              fontWeight: 600,
              mb: '1rem',
            }}
          >
            {t('ContactView.agents.title')}
          </Typography>
          <Typography
            sx={{
              maxWidth: 600,
              color: colors.gray1,
              fontSize: ['16px', undefined, '18px'],
              lineHeight: ['24px', undefined, '28px'],
              whiteSpace: 'pre-line',
            }}
          >
            {t('ContactView.agents.description')}
          </Typography>
        </Grid>
        <Grid container item xs alignItems='center' gap={3}>
          <PersonContactInfo
            imgSrc={margusMaiste}
            name='Margus Maiste'
            title={t('ContactView.agents.professions.SALES_MANAGER_OPERATORS')}
            email='m&#97;rgus&#46;maiste&#64;mobif&#101;r&#46;&#99;om'
            href='ma&#105;l&#116;o&#58;mar%67us&#46;%&#54;Dai&#115;t&#101;%40%6Dobi&#102;er&#46;%63&#111;&#37;6D'
            phone='+37253448238'
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function ContactView() {
  const { t } = useTranslation();
  return (
    <>
      <AppMenu />
      <MapDisplay isContactPage />
      <Grid
        container
        sx={{
          position: 'relative',
          zIndex: 52,
          minHeight: ['auto', undefined, 'calc(100vh - 64px)'],
          paddingTop: ['4rem', undefined, '6rem'],
          px: '1rem',
          pb: ['2rem', null],
          justifyContent: 'center',
        }}
      >
        <Grid container item maxWidth='xl' justifyContent='center' gap={[16, undefined, 8]}>
          <Grid
            item
            width='402px'
            sx={{
              marginRight: { xs: '0', md: '100px' },
            }}
          >
            <Typography
              component='h1'
              sx={{
                fontWeight: 700,
                marginBottom: '24px',
                lineHeight: {
                  xs: '44px',
                  sm: '60px',
                },
                fontSize: {
                  xs: '36px',
                  sm: '48px',
                },
              }}
            >
              {t('ContactView.title')}
            </Typography>
            <Typography
              component='h2'
              sx={{
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '30px',
                whiteSpace: 'pre-line',
              }}
            >
              {t('ContactView.description')}
            </Typography>
          </Grid>
          <Grid item width='484px'>
            <Paper
              elevation={1}
              sx={{
                border: `1px solid ${colors.tiffanyBlue3}`,
                padding: ['2rem', undefined, '3rem 3rem 6rem 3rem'],
              }}
            >
              <Typography
                sx={{
                  mb: '2rem',
                  fontSize: ['24px', undefined, '30px'],
                  lineHeight: ['30px', undefined, '38px'],
                  color: colors.primary,
                  fontWeight: 700,
                }}
              >
                {t('ContactView.form.title')}
              </Typography>
              <ContactForm />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <ContactInfo />
      <Footer />
    </>
  );
}

export default ContactView;
