import { Box, Paper, Typography } from '@mui/material';
import {
  StepperContainer,
  colors,
  DecodedOrderToken,
  OrderPaymentStatusEnum,
  PageLoader,
  PriceDisplay,
  OrderDetails,
  OrderDtoSchemaType,
  PaymentMethod,
  ClientType,
} from 'remote/global';
import jwtDecode from 'jwt-decode';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { track } from 'utils/tracking';
import { useOffersPaymentStatusQuery } from 'api/queries/offers';
import { useOrdersByBookingNumberQuery } from 'api/queries/orders';
import { ROUTES } from 'utils/routes';
import { OrderFormStep, OrderStepKey, orderStepArray } from 'components/CreateOrder';
import { UseQueryResult } from '@tanstack/react-query';

type BookingCompletedContainerProps = {
  steps: OrderFormStep[];
  title: React.ReactNode | string;
  subtitle: React.ReactNode | string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
};

function BookingCompletedContainer({
  steps,
  title,
  subtitle,
  icon,
  children,
}: BookingCompletedContainerProps) {
  const lastStep = steps[steps.length - 1];

  return (
    <StepperContainer
      steps={steps}
      activeStep={lastStep.stepIndex}
      maxWidth='533px'
      env={window.MOBIFER_ENV}
    >
      <Box
        textAlign='center'
        mt={{ xs: '3rem' }}
        sx={{ display: 'flex', flexDirection: 'column', px: 1 }}
      >
        {icon != null && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 1 }}>
            {icon}
          </Box>
        )}
        <Typography color={colors.primary} fontWeight='600' fontSize='30px'>
          {title}
        </Typography>
        <Typography fontSize='16px' variant='body2' mb='29px' color={colors.gray1} mt='12px'>
          {subtitle}
        </Typography>
        {children}
      </Box>
    </StepperContainer>
  );
}

function OrderCompleteView() {
  const { bookingNumber, uuid } = useParams();
  const navigate = useNavigate();
  const translationInstance = useTranslation();
  const { t } = translationInstance;
  const [isTracked, setIsTracked] = useState(false);
  const [searchParams] = useSearchParams();
  const orderToken = searchParams.get('order-token');

  const isFetchPaymentStatusEnabled = orderToken != null;
  const {
    data: paymentStatus,
    isFetched: isPaymentStatusFetched,
    isFetching: isFetchingPaymentStatus,
  } = useOffersPaymentStatusQuery(isFetchPaymentStatusEnabled ? bookingNumber : undefined);

  // Checks if payment status is available and that BE has recieved information about that payment
  const isPaymentStatusQueryPolling =
    isFetchPaymentStatusEnabled &&
    ![paymentStatus?.orderCreated, paymentStatus?.expired, paymentStatus?.failed].some(Boolean);

  const isFetchOrderEnabled =
    !isFetchPaymentStatusEnabled || (isPaymentStatusFetched && !isPaymentStatusQueryPolling);

  const orderQuery = useOrdersByBookingNumberQuery(
    isFetchOrderEnabled ? bookingNumber! : undefined
  );
  const {
    data: order,
    isError: isOrderError,
    isFetching: isFetchingOrder,
    isSuccess: isSuccessOrder,
  } = orderQuery;

  const isFetching = isFetchingPaymentStatus || isPaymentStatusQueryPolling || isFetchingOrder;

  // Redirect the customer to retry the payment if it is not PAID
  useEffect(() => {
    if (orderToken != null && orderToken.length > 0) {
      const decoded: DecodedOrderToken = jwtDecode(orderToken);
      if (decoded.paymentStatus !== OrderPaymentStatusEnum.PAID) {
        // Redirect the user to complete the payment again
        navigate(`/offers/continue-booking/${bookingNumber}/${uuid}?retryPayment`);
      }
    }
  }, [bookingNumber, navigate, orderToken, uuid]);

  const shouldTrack = useMemo(() => {
    if (isTracked) return false;
    if (!uuid || !order) return null;

    // For PAY_LATER there is no orderToken as we don't redirect back from the payment provider
    if (order.paymentMethod === PaymentMethod.PAY_LATER) return true;

    if (!orderToken) return false;

    const decoded: DecodedOrderToken = jwtDecode(orderToken);

    // Determine if the order is PAID
    return decoded.paymentStatus === OrderPaymentStatusEnum.PAID;
  }, [isTracked, uuid, order, orderToken]);

  useEffect(() => {
    if (shouldTrack) {
      track.purchase(order!, uuid!);
      // Make sure to set the boolean
      setIsTracked(true);
    }
  }, [shouldTrack, order, uuid]);

  const steps: OrderFormStep[] = [
    {
      stepKey: OrderStepKey.CLIENT_DATA,
      stepIndex: orderStepArray.indexOf(OrderStepKey.CLIENT_DATA),
      label: t('OrderView.steps.clientDetails.title'),
      description: '',
      visible: true,
    },
    {
      stepKey: OrderStepKey.GROUP_LEAD,
      stepIndex: orderStepArray.indexOf(OrderStepKey.GROUP_LEAD),
      label: t('OrderView.steps.groupLead.title'),
      description: t('OrderView.steps.groupLead.subtitle'),
      visible: true,
    },
    {
      stepKey: OrderStepKey.ACCOMMODATION,
      stepIndex: orderStepArray.indexOf(OrderStepKey.ACCOMMODATION),
      label: t('OrderView.steps.accommodation.title'),
      description: t('OrderView.steps.accommodation.subtitle'),
      visible: order?.overnight ?? false,
    },
    {
      stepKey: OrderStepKey.PAYMENT,
      stepIndex: orderStepArray.indexOf(OrderStepKey.PAYMENT),
      label: t('OrderView.steps.payment.title'),
      description: '',
      visible: true,
    },
  ].filter((el) => el.visible);

  if (isFetching) {
    return (
      <BookingCompletedContainer steps={steps} title='' subtitle=''>
        <PageLoader />
      </BookingCompletedContainer>
    );
  }

  // Redirect user when no order found (expired or otherwise)
  if (isOrderError) {
    return <Navigate to={ROUTES.BaseRoutes.ROOT} replace />;
  }

  if (!isSuccessOrder) return null;

  return (
    <BookingCompletedContainer
      steps={steps}
      title={t('OrderView.completed.title')}
      subtitle={
        <>
          {t('OrderView.completed.subtitle')}
          <br />
          {t('OrderView.completed.description')}
        </>
      }
    >
      <Box data-testid='order-complete'>
        <Paper elevation={3}>
          <OrderDetails
            /* TODO: Fix this reassignment */
            orderQuery={orderQuery as unknown as UseQueryResult<OrderDtoSchemaType>}
            title={`#${bookingNumber}`}
            header={{
              children:
                orderQuery.data != null ? (
                  <PriceDisplay
                    priceExclVat={orderQuery.data.priceExclVat}
                    priceInclVat={orderQuery.data.priceInclVat}
                    unadjustedPriceExclVat={orderQuery.data.unadjustedPriceExclVat}
                    unadjustedPriceInclVat={orderQuery.data.unadjustedPriceInclVat}
                    clientListPriceInclVat={orderQuery.data.clientListPriceInclVat}
                    clientListPriceExclVat={orderQuery.data.clientListPriceExclVat}
                    defaultValue
                    hideSwitch={orderQuery.data.client?.type !== ClientType.BUSINESS}
                  />
                ) : undefined,
            }}
          />
        </Paper>
      </Box>
    </BookingCompletedContainer>
  );
}

export default OrderCompleteView;
